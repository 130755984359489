@font-face {
  font-family: 'HK Grotesk';
  src: local('HK Grotesk Light'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-Light.woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'HK Grotesk Legacy';
  src: url('../../../assets/fonts/HKGroteskPro/HKGrotesk-LegacyItalic.eot');
  src: local('HK Grotesk Legacy Italic'), local('HKGrotesk-LegacyItalic'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-LegacyItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-LegacyItalic.woff2')
      format('woff2'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-LegacyItalic.woff')
      format('woff'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-LegacyItalic.ttf')
      format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'HK Grotesk Light Legacy';
  src: url('../../../assets/fonts/HKGroteskPro/HKGrotesk-LightLegacyItalic.eot');
  src: local('HK Grotesk Light Legacy Italic'),
    local('HKGrotesk-LightLegacyItalic'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-LightLegacyItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-LightLegacyItalic.woff2')
      format('woff2'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-LightLegacyItalic.woff')
      format('woff'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-LightLegacyItalic.ttf')
      format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('../../../assets/fonts/HKGroteskPro/HKGrotesk-SemiBoldItalic.eot');
  src: local('HK Grotesk SemiBold Italic'), local('HKGrotesk-SemiBoldItalic'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-SemiBoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-SemiBoldItalic.woff2')
      format('woff2'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-SemiBoldItalic.woff')
      format('woff'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-SemiBoldItalic.ttf')
      format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('../../../assets/fonts/HKGroteskPro/HKGrotesk-BoldItalic.eot');
  src: local('HK Grotesk Bold Italic'), local('HKGrotesk-BoldItalic'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-BoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-BoldItalic.woff2')
      format('woff2'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-BoldItalic.woff')
      format('woff'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-BoldItalic.ttf')
      format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'HK Grotesk Medium Legacy';
  src: url('../../../assets/fonts/HKGroteskPro/HKGrotesk-MediumLegacy.eot');
  src: local('HK Grotesk Medium Legacy'), local('HKGrotesk-MediumLegacy'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-MediumLegacy.eot?#iefix')
      format('embedded-opentype'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-MediumLegacy.woff2')
      format('woff2'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-MediumLegacy.woff')
      format('woff'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-MediumLegacy.ttf')
      format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('../../../assets/fonts/HKGroteskPro/HKGrotesk-Bold.eot');
  src: local('HK Grotesk Bold'), local('HKGrotesk-Bold'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-Bold.woff2')
      format('woff2'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-Bold.woff') format('woff'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-Bold.ttf')
      format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('../../../assets/fonts/HKGroteskPro/HKGrotesk-Medium.eot');
  src: local('HK Grotesk Medium'), local('HKGrotesk-Medium'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-Medium.woff2')
      format('woff2'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-Medium.woff')
      format('woff'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-Medium.ttf')
      format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('../../../assets/fonts/HKGroteskPro/HKGrotesk-ExtraBold.eot');
  src: local('HK Grotesk ExtraBold'), local('HKGrotesk-ExtraBold'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-ExtraBold.eot?#iefix')
      format('embedded-opentype'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-ExtraBold.woff2')
      format('woff2'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-ExtraBold.woff')
      format('woff'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-ExtraBold.ttf')
      format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'HK Grotesk Light Legacy';
  src: url('../../../assets/fonts/HKGroteskPro/HKGrotesk-LightLegacy.eot');
  src: local('HK Grotesk Light Legacy'), local('HKGrotesk-LightLegacy'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-LightLegacy.eot?#iefix')
      format('embedded-opentype'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-LightLegacy.woff2')
      format('woff2'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-LightLegacy.woff')
      format('woff'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-LightLegacy.ttf')
      format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('../../../assets/fonts/HKGroteskPro/HKGrotesk-SemiBold.eot');
  src: local('HK Grotesk SemiBold'), local('HKGrotesk-SemiBold'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-SemiBold.eot?#iefix')
      format('embedded-opentype'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-SemiBold.woff2')
      format('woff2'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-SemiBold.woff')
      format('woff'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-SemiBold.ttf')
      format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('../../../assets/fonts/HKGroteskPro/HKGrotesk-Italic.eot');
  src: local('HK Grotesk Italic'), local('HKGrotesk-Italic'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-Italic.eot?#iefix')
      format('embedded-opentype'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-Italic.woff2')
      format('woff2'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-Italic.woff')
      format('woff'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-Italic.ttf')
      format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'HK Grotesk Legacy';
  src: url('../../../assets/fonts/HKGroteskPro/HKGrotesk-BoldLegacyItalic.eot');
  src: local('HK Grotesk Bold Legacy Italic'),
    local('HKGrotesk-BoldLegacyItalic'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-BoldLegacyItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-BoldLegacyItalic.woff2')
      format('woff2'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-BoldLegacyItalic.woff')
      format('woff'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-BoldLegacyItalic.ttf')
      format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('../../../assets/fonts/HKGroteskPro/HKGrotesk-LightItalic.eot');
  src: local('HK Grotesk Light Italic'), local('HKGrotesk-LightItalic'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-LightItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-LightItalic.woff2')
      format('woff2'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-LightItalic.woff')
      format('woff'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-LightItalic.ttf')
      format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('../../../assets/fonts/HKGroteskPro/HKGrotesk-Light.eot');
  src: local('HK Grotesk Light'), local('HKGrotesk-Light'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-Light.eot?#iefix')
      format('embedded-opentype'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-Light.woff2')
      format('woff2'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-Light.woff')
      format('woff'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-Light.ttf')
      format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('../../../assets/fonts/HKGroteskPro/HKGrotesk-Regular.eot');
  src: local('HK Grotesk Regular'), local('HKGrotesk-Regular'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-Regular.woff2')
      format('woff2'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-Regular.woff')
      format('woff'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-Regular.ttf')
      format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HK Grotesk Legacy';
  src: url('../../../assets/fonts/HKGroteskPro/HKGrotesk-RegularLegacy.eot');
  src: local('HK Grotesk Regular Legacy'), local('HKGrotesk-RegularLegacy'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-RegularLegacy.eot?#iefix')
      format('embedded-opentype'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-RegularLegacy.woff2')
      format('woff2'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-RegularLegacy.woff')
      format('woff'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-RegularLegacy.ttf')
      format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HK Grotesk SemiBold Legacy';
  src: url('../../../assets/fonts/HKGroteskPro/HKGrotesk-SemiBoldLegacy.eot');
  src: local('HK Grotesk SemiBold Legacy'), local('HKGrotesk-SemiBoldLegacy'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-SemiBoldLegacy.eot?#iefix')
      format('embedded-opentype'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-SemiBoldLegacy.woff2')
      format('woff2'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-SemiBoldLegacy.woff')
      format('woff'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-SemiBoldLegacy.ttf')
      format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('../../../assets/fonts/HKGroteskPro/HKGrotesk-Black.eot');
  src: local('HK Grotesk Black'), local('HKGrotesk-Black'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-Black.eot?#iefix')
      format('embedded-opentype'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-Black.woff2')
      format('woff2'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-Black.woff')
      format('woff'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-Black.ttf')
      format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'HK Grotesk SmBold Legacy';
  src: url('../../../assets/fonts/HKGroteskPro/HKGrotesk-SemiBoldLegacyItalic.eot');
  src: local('HK Grotesk SemiBold Legacy Italic'),
    local('HKGrotesk-SemiBoldLegacyItalic'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-SemiBoldLegacyItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-SemiBoldLegacyItalic.woff2')
      format('woff2'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-SemiBoldLegacyItalic.woff')
      format('woff'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-SemiBoldLegacyItalic.ttf')
      format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'HK Grotesk Legacy';
  src: url('../../../assets/fonts/HKGroteskPro/HKGrotesk-BoldLegacy.eot');
  src: local('HK Grotesk Bold Legacy'), local('HKGrotesk-BoldLegacy'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-BoldLegacy.eot?#iefix')
      format('embedded-opentype'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-BoldLegacy.woff2')
      format('woff2'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-BoldLegacy.woff')
      format('woff'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-BoldLegacy.ttf')
      format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'HK Grotesk Medium Legacy';
  src: url('../../../assets/fonts/HKGroteskPro/HKGrotesk-MediumLegacyItalic.eot');
  src: local('HK Grotesk Medium Legacy Italic'),
    local('HKGrotesk-MediumLegacyItalic'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-MediumLegacyItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-MediumLegacyItalic.woff2')
      format('woff2'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-MediumLegacyItalic.woff')
      format('woff'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-MediumLegacyItalic.ttf')
      format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('../../../assets/fonts/HKGroteskPro/HKGrotesk-MediumItalic.eot');
  src: local('HK Grotesk Medium Italic'), local('HKGrotesk-MediumItalic'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-MediumItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-MediumItalic.woff2')
      format('woff2'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-MediumItalic.woff')
      format('woff'),
    url('../../../assets/fonts/HKGroteskPro/HKGrotesk-MediumItalic.ttf')
      format('truetype');
  font-weight: 500;
  font-style: italic;
}


@font-face {
  font-family: 'Cinzel Decorative';
  src: url('../../../assets/fonts/CinzelDecorative/CinzelDecorative-Regular.ttf');
  src: url('../../../assets/fonts/CinzelDecorative/CinzelDecorative-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Cinzel Decorative';
  src: url('../../../assets/fonts/CinzelDecorative/CinzelDecorative-Bold.ttf');
  src: url('../../../assets/fonts/CinzelDecorative/CinzelDecorative-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Cinzel Decorative';
  src: url('../../../assets/fonts/CinzelDecorative/CinzelDecorative-Black.ttf');
  src: url('../../../assets/fonts/CinzelDecorative/CinzelDecorative-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}