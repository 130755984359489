@import '../constants/colors.scss';
@import '../../components/constants/typography.scss';

%button {
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 85px;
  min-height: 32px;

  padding: 5px 10px;
  margin-right: 10px;

  border-radius: 4px;

  font-family: HK Grotesk, Roboto, -apple-system, BlinkMacSystemFont, Segoe UI,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  // Remove default button stylings
  border: none;
  box-shadow: none;
  background-color: transparent;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transform: translate(-1px, -1px);
  }

  &.sm {
    min-height: 26px;
    font-size: 0.65rem;
    font-weight: 700;
  }
}

.spinnerWrapper {
  margin-right: 4px;
}

.primary {
  @extend %button;

  color: $white;
  background-color: $blue;

  [class~='MuiCircularProgress-svg'] {
    color: $white;
  }

  &:disabled {
    background-color: $inactive-primary;
    transform: none;
    box-shadow: none;
    cursor: not-allowed;
  }
}

.success {
  @extend %button;

  color: $white;
  background-color: $green;

  [class~='MuiCircularProgress-svg'] {
    color: $white;
  }

  &:disabled {
    background-color: $inactive-success;
    transform: none;
    box-shadow: none;
    cursor: not-allowed;
  }
}

.delete {
  @extend %button;

  color: $white;
  background-color: $orange;

  [class~='MuiCircularProgress-svg'] {
    color: $white;
  }

  &:disabled {
    background-color: $inactive-delete;
    transform: none;
    box-shadow: none;
    cursor: not-allowed;
  }
}

.addFilter {
  @extend %button;

  color: $white;
  background-color: $blue;
  font-size: 0.7em;
  padding: 0.6em;

  transition: width 1s;
}

// side panel vessel collapse trash icon button
.trash {
  @extend %button;

  margin: 0;
  width: 100%;
  padding: 0;
  border-radius: 0;

  background-color: red;
  color: $white;

  min-width: unset;

  &:hover {
    background-color: #e00000;
    box-shadow: none;
  }
}

.secondary {
  @extend %button;

  // color: $text-black;
  color: $blue;
  background-color: $white;

  border: 1px solid $border-gray;

  &:disabled {
    color: $inactive-primary;
    background-color: $inactive-secondary;
    transform: none;
    box-shadow: none;
    cursor: not-allowed;
  }
}

.icon {
  @extend %button;

  min-width: unset;
  min-height: unset;

  &:hover {
    transform: unset;
    box-shadow: unset;
  }
}

.icon-with-text {
  @extend %button;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 0;
}
