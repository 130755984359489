@import '../constants/colors.scss';

// These classnames are used to manually set the z-index for mapbox popups

@for $i from 0 through 30 {
  .mapboxgl-popup-zindex-#{$i} {
    z-index: $i !important;
    &:hover {
      z-index: 30 !important;
    }
  }
}
