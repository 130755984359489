// General
$blue: #385dea;
$route-blue: #869bf5;
$light-blue: #bbc7f7;
$border-gray: #e0e0e0;
$gray: #f5f5f5;
$light-gray: #fafafa;
$black: #000;
$white: #fff;
$red: red;
$orange: #FF6B6B;
$green: #50b72e;

// Typography
$text-black: #2a2a2c;
$icon-gray: #bdbdbd;
$text-gray: #9e9e9e;

// Button Colors
$inactive-primary: #889ef3;
$inactive-secondary: #f8f8f8;
$inactive-delete: #FF6B6B33;
$inactive-success: #50b72e30;

$inactive-text-white: rgba(255, 255, 255, 0.4);
$inactive-text-secondary: $text-gray;
